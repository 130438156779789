<!-- step menu -->
<div class="mb-5">
  <kiwimonster-step-menu [currentKMQuoteStep]=1 class='quote-step-menu'></kiwimonster-step-menu>
</div>

<!-- quote name -->
<div class="form-group row">
  <label for="quoteName" class="col-auto col-form-label">Quote Name:</label>
  <div class='col-auto'>
    <input type="text" class='form-control' id='quoteName'
           [(ngModel)]="quoteName"
           (focus)="onQuoteNameFocus()"
           (ngModelChange)='onValueChange()' appAutoSelect required />
  </div>
</div>


<ul class="list-group list-group-flush">
  <!-- header -->
  <li class="py-4 list-group-item qm-bg-catskillWhite">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
      <h4 class="mb-4 mb-md-0">Client Details</h4>
      <div>
        <!-- save&close button -->
        <kiwimonster-save-close-btn (saveAndCloseClicked)="onSaveAndClose()"> </kiwimonster-save-close-btn>

        <!-- next button -->
        <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                (click)='goNext();' [disabled]='noResidentForTaxClient'>
          <span class="d-none d-sm-inline-block">Next</span>
          <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
      </div>
    </div>
  </li>

  <!-- add adult button -->
  <li class="list-group-item px-0 pt-4">
    <button mat-raised-button color="qm-pumpkin" *ngIf='currentKMQuote.Clients.length < 6'
            class='mr-4 mb-2 mb-sm-0 w-sm-100'
            (click)='addClient(); onValueChange(); getQuoteName(); loginService.doGoogleTracking("kiwiMonsterQuote-client", "addAdult", "");'>
      <span class="material-icons">add</span>&nbsp;Add an Adult
    </button>
  </li>

  <!-- client part -->
  <li class="list-group-item px-0 border-top-0">

    <mat-tab-group animationDuration="0ms" class='main-client-group' [selectedIndex]='currentClientIndex'
                   appRealignInkBar>
      <!-- adult tab -->
      <ng-container *ngFor="let client of currentKMQuote.Clients">
        <mat-tab *ngIf="!client.IsDeleted">
          <!-- adult tab label -->
          <ng-template mat-tab-label>
            <ng-container *ngIf='client.Gender === 1'>
              <img class='sm-img' src="/assets/images/client-type/1.png?v=2" />
            </ng-container>
            <ng-container *ngIf='client.Gender !== 1'>
              <img class='sm-img' src="/assets/images/client-type/2.png?v=2" />
            </ng-container>
            {{getClientTabName(client)}}
          </ng-template>
          <!-- adult tab label end -->

          <!-- adult client detail -->
          <form #clientsForms='ngForm'>
            <div class='row'>
              <div class='col-md-6 px-5'>
                <!-- first name -->
                <mat-form-field class='first-name mb-md-3'>
                  <input matInput placeholder="First name" name='firstName' [(ngModel)]="client.FirstName"
                         (ngModelChange)='onValueChange(); getQuoteName(); ' Autofocus appAutofocus appAutoSelect
                         required />
                  <mat-error>
                    {{sharedFunction.getUiMessageByCode('Share-ERROR-EnterFirstName')}}
                  </mat-error>
                </mat-form-field>
                <!-- last name -->
                <mat-form-field class='last-name'>
                  <input matInput placeholder="Last Name" name='lastName' [(ngModel)]="client.LastName"
                         (ngModelChange)='onValueChange(); getQuoteName(); '
                         required appAutoSelect>
                  <mat-error>
                    {{sharedFunction.getUiMessageByCode('Share-ERROR-EnterLastName')}}
                  </mat-error>
                </mat-form-field>

                <!-- is resident for tax purposes -->
                <mat-checkbox [(ngModel)]='client.Setting.AreYouNZTaxResident' name='areYouNZTaxResident' (ngModelChange)='onValueChange()'>
                  Are you an NZ resident for tax purposes?
                </mat-checkbox>
                <a mat-icon-button class='align-bottom' [href]='sysConfig.requiredData.JoiningKiwiSaverHelpLink' target="_blank"
                  (click)="loginService.doGoogleTracking('kiwiMonsterQuote-client', 'go to joining kiwisaver help link', '');"
                  title='info'>
                  <span class="material-icons" aria-hidden="false" aria-label="info">help</span>
                </a>
              </div>
              <div class='col-md-6 px-5'>
                <!-- date of birthday -->
                <div class='row mb-md-3'>
                  <div class='col-md-4 mb-3 mb-md-0'>
                    <mat-form-field>
                      <input matInput type="text" #day placeholder="DD" name='dobDay' maxlength="2"
                             (ngModelChange)='onValueChange()'
                             (keyup)='onDateOfBirthChange(client,day, month);'
                             [(ngModel)]="client.DoBDay" required appAutoSelect>
                      <mat-hint>Day(dd)</mat-hint>
                      <mat-error>
                        Day(dd)
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class='col-md-4 mb-3 mb-md-0'>
                    <mat-form-field>
                      <input matInput type="text" #month placeholder="MM" name='dobMonth' maxlength="2"
                             (ngModelChange)='onValueChange()'
                             (keyup)='onDateOfBirthChange(client,month, year);'
                             [(ngModel)]="client.DoBMonth" required appAutoSelect>
                      <mat-hint>Month(mm)</mat-hint>
                      <mat-error>
                        Month(mm)
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class='col-md-4 mb-3 mb-md-0'>
                    <mat-form-field>
                      <input matInput type="text" #year placeholder="YYYY" name='dobYear' maxlength="4"
                             (ngModelChange)='onValueChange()'
                             (keyup)='onDateOfBirthChange(client);'
                             [(ngModel)]="client.DoBYear" required appAutoSelect>
                      <mat-hint>Year(yyyy)</mat-hint>
                      <mat-error>
                        Year(yyyy)
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <!-- age -->
                <app-select-search placeholderLabel='Select an age' [matFormFieldClasses]="['age']"
                                   [selectedData]='client.Age'
                                   (onSelectedDataChange)="client.Age=$event; onAgeChange(client); onValueChange()"
                                   [selectOptions]='clientAgeList'
                                   [errorMessage]='sharedFunction.getUiMessageByCode("QuoteClient-ERROR-AdultDOB")'
                                   *ngIf="clientAgeList.length > 0">
                </app-select-search>
                <div class="alert alert-danger mb-5" role="alert" *ngIf="!client.IsValidDateOfBirth">
                  {{sharedFunction.getUiMessageByCode("QuoteClient-ERROR-AdultDOB")}}
                </div>
                <!-- gender -->
                <!-- <mat-radio-group [(ngModel)]="client.Gender" name="{{'gender_' + client.Index}}" required class='gender'
                                 (ngModelChange)='onValueChange()'>
                  <div class='row mb-3'>
                    <div class='col-md-6'>
                      <mat-radio-button [value]="1">Male</mat-radio-button>
                    </div>
                    <div class='col-md-6'>
                      <mat-radio-button [value]="2">Female</mat-radio-button>
                    </div>
                  </div>
                </mat-radio-group> -->
                <!-- smoker -->
                <!-- <mat-radio-group [(ngModel)]="client.IsSmoker" name="{{'isSmoker_' + client.Index}}" class='smoker'
                                 (ngModelChange)='onValueChange()'>
                  <div class='row mb-3'>
                    <div class='col-md-6'>
                      <mat-radio-button [value]=false>Non-Smoker </mat-radio-button>
                    </div>
                    <div class='col-md-6'>
                      <mat-radio-button [value]=true>Smoker </mat-radio-button>
                      <button mat-icon-button color="qm-blue" class="align-bottom"
                              (click)="sharedFunction.openSnackBar('Client-detail-smoker-tip', 'Close', 20000)"
                              matTooltip="Some insurers treat non-nicotine vapers as non-smokers.">
                        <span class="material-icons" aria-label="Config">help_outline</span>
                      </button>
                    </div>
                  </div>
                </mat-radio-group> -->

                <!-- employed -->
                <!-- <mat-form-field class='employed'>
                  <mat-select placeholder="Employed" name='employed' [(ngModel)]="client.EmployedStatus" required
                              (selectionChange)='onValueChange()'>
                    <mat-option [value]="1">Employed</mat-option>
                    <mat-option [value]="2">Self-Employed &gt; 3 Years</mat-option>
                    <mat-option [value]="3">Self-Employed &lt; 3 Years</mat-option>
                    <mat-option [value]="4">Non-Earner</mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>

            </div>
          </form>
          <!-- adult client detail end-->

          <!-- adult client remove button -->
          <div class='row mt-5' *ngIf='!showRemoveMessage'>
            <div class='col-12 text-center' *ngIf="client.Index > 0">
              <button mat-raised-button color="qm-darkGray" (click)='showRemoveMessage = true;'>Remove</button>
            </div>
          </div>
          <div class='row mt-5' *ngIf='showRemoveMessage'>
            <div class='col text-center px-5'>
              <span class='d-block alert alert-warning mb-3'>
                {{sharedFunction.getUiMessageByCode("QuoteClient-WARNING-DeletePerson")}}
              </span>
              <button mat-raised-button color="warn" class='mx-2'
                      (click)='showRemoveMessage = false; removeClient(client);onValueChange(); getQuoteName(); loginService.doGoogleTracking("kiwiMonsterQuote-client", "removeAdult", "");'>
                Yes
              </button>
              <button mat-raised-button color="qm-black" class='mx-2' (click)='showRemoveMessage = false;'>
                Cancel
              </button>
            </div>
          </div>
          <!-- adult client remove button end -->
        </mat-tab>
      </ng-container>
    </mat-tab-group>

  </li>

  <!-- error messages -->
  <li class="list-group-item px-0" *ngIf='errorMessages.length > 0'>
    <div class="alert alert-danger" role="alert" *ngFor='let e of errorMessages'>
      {{e}}
    </div>
  </li>


  <li class='list-group-item px-0'>
    <button mat-raised-button color="qm-blue" class='float-right'
            (click)='goNext()' [disabled]='noResidentForTaxClient'>
      Next
      <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
    </button>
  </li>
</ul>