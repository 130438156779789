<div class='d-flex align-items-end'>
  <ng-container *ngIf="isWhiteTextLogo">
    <!-- kiwimonster -->
    <ng-container *ngIf="isDevMode">
      <button mat-button class='disable-ripple' (click)='updatePlatformSetting(3, true)' title='kiwimonster'>
        <img class='align-bottom' [ngClass]="{'small-logo': getCurrentPlatformSetting() !== 3}"
             src="assets/images/qm-logos/Kiwimonster-Logo-Light.png?v=2" alt="km-logo">
      </button>
    </ng-container>
    <!-- personal -->
    <button mat-button class='disable-ripple' (click)='updatePlatformSetting(1, true)' title='personal'>
      <img class='align-bottom' [ngClass]="{'small-logo': getCurrentPlatformSetting() !== 1}"
           src="assets/images/qm-logos/Quotemonster-Logo-Light-P.png?v=2" alt="qm-logo-p">
    </button>
    <!-- not AM user: business -->
    <ng-container *ngIf="!loginService.checkUserAccessRole(2)">
      <a mat-button class='disable-ripple' [routerLink]="['/research/subscription/plan']"
         (click)='loginService.doGoogleTracking("nav-menu-business-logo", "go to pricing plan", "");' title='business'>
        <img class='align-bottom' [ngClass]="{'small-logo': getCurrentPlatformSetting() !== 2}"
             src="assets/images/qm-logos/Quotemonster-Logo-Light-B.png?v=2" alt="qm-logo-b">
      </a>
    </ng-container>
    <!-- AM user: business -->
    <ng-container *ngIf="loginService.checkUserAccessRole(2)">
      <button mat-button class='disable-ripple' (click)='updatePlatformSetting(2, true)' title='business'>
        <img class='align-bottom' [ngClass]="{'small-logo': getCurrentPlatformSetting() !== 2}"
             src="assets/images/qm-logos/Quotemonster-Logo-Light-B.png?v=2" alt="qm-logo-b">
      </button>
    </ng-container>
  </ng-container>
</div>