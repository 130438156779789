<div class="dashboard-page">
  <!-- alerts section-->
  <div class='row' *ngFor="let alert of systemAlerts.List; index as i;">
    <div class='col-12'>
      <div class="alert alert-dismissible fade show"
        [ngClass]="{'alert-danger': alert.Type === 1, 'alert-success': alert.Type === 2, 'alert-info': alert.Type > 2}"
        role="alert">
        <strong class="alert-heading">{{alert.Subject}}</strong>
        <p>{{alert.Contents}}</p>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)='removeSystemAlert(i);'>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
  <!-- alerts section end -->
  <div class='row'>
    <!-- quote table section -->
    <div class='col-12'>
      <div class='d-flex flex-column justify-content-between min-height-600px'>
        <div>
          <ul class="list-group">
            <!-- search -->
            <li class="list-group-item qm-bg-catskillWhite px-4 search-section">
              <div class='row align-items-center'>
                <div class='col-sm-4 mb-3 mb-sm-0'>
                  <h4 class="mb-0">Recent Saved Personal Quotes</h4>
                </div>
                <div class='col-sm-4'>
                  <div class="input-group px-3 rounded-lg align-items-center bg-white">
                    <input class="form-control border-0 px-0" type="text" placeholder="Find Your Client"
                           (keyup)="applyFilter($event.target.value)">
                    <div class="input-group-append">
                      <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
                    </div>
                  </div>
                </div>
                <!-- create new button -->
                <div class='col-sm-4'>
                  <button mat-flat-button color="qm-blue" class="float-right w-sm-100" title='create new'
                          (click)='startNewQuote();loginService.doGoogleTracking("dashboard", "create new quote", "");'>
                    <span class="material-icons">add</span>&nbsp;New Personal Quote
                  </button>
                </div>
              </div>
            </li>
            <!-- search end -->
  
            <!-- table -->
            <li class="list-group-item p-0 border-top-0 rounded-0">
  
              <mat-table [dataSource]="dataSource" matSort class='w-100 data-history-table'>
                <!-- Who Column -->
                <ng-container matColumnDef="FamilyName">
                  <mat-header-cell *matHeaderCellDef class="text-black qm-text-mid font-weight-bold pr-4">Client
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="px-4">
                    <button mat-button type="button"
                            class="ml-n3 my-2 py-1 text-left line-height-none text-transform-none" aria-hidden="true"
                            (click)='openQuote(row);loginService.doGoogleTracking("dashboard", "open saved quote", "");'
                            [title]="row.FamilyName">
                      <img width="24" height="24" src="/assets/images/client-type/{{row.UserType}}.png?v=2" />
                      <span
                            class="d-inline-block qm-text-sm text-blue font-weight-bolder px-2 text-break text-wrap">{{row.FamilyName}}{{row.QuoteName?"
                        - " + row.QuoteName:''}}</span>
                      <div *ngIf='row.NumberOfChild > 0' class="mt-1 ml-1">
                        <img width="18" height="24" src="/assets/images/client-type/child.png?v=2" />
                        <span class='qm-text-sm text-blue font-weight-bolder px-1'>&times;
                          {{row.NumberOfChild}}</span>
                      </div>
                    </button>
                  </mat-cell>
                </ng-container>
  
                <!-- When Column -->
                <ng-container matColumnDef="LastUpdatePrettyDate">
                  <mat-header-cell *matHeaderCellDef class="text-black qm-text-mid font-weight-bold pr-4">When
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="pl-4 pl-sm-0 pr-4">
                    <span class="text-black-50 qm-text-sm">
                      <ng-container
                                    *ngIf="row.LastUpdatePrettyDate; else updateDate">{{row.LastUpdatePrettyDate}}</ng-container>
                      <ng-template #updateDate>{{ row.LastUpdateDate | date:'dd/MM/y hh:mm a' | lowercase }}</ng-template>
                    </span>
                  </mat-cell>
                </ng-container>
  
  
                <!-- View Column -->
                <ng-container matColumnDef="Action">
                  <mat-header-cell *matHeaderCellDef class="text-black qm-text-mid font-weight-bold pr-4">Action
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row" class="flex-wrap pl-4 pl-sm-0 pb-2 pb-sm-0">
                    <!-- quote actions start -->
                    <div class="col-12 px-0">
                      <div *ngIf='row.AppId > 0' class='row no-gutters mr-n3' role="toolbar">
                        <div class='col'
                             [ngClass]="{'d-none':!loginService.checkUserAccessRole(accessRole.USER_ROLE_QM)}">
                          <button mat-icon-button class="text-black-50" type="button"
  
                                  *ngIf="loginService.checkUserAccessRole(accessRole.USER_ROLE_QM)" title='Show Reports'
                                  (click)='showReports(row);loginService.doGoogleTracking("dashboard", "viewReport", "");'
                                  aria-label="reports">
                            <span class="material-icons">picture_as_pdf</span>
                          </button>
                        </div>
                        <div class='col'>
                          <button mat-icon-button class="text-black-50" type="button"
                                  (click)='viewQuote(row);loginService.doGoogleTracking("dashboard", "View Quote Result", "");'
                                  title='View Quote Result' aria-label="files">
                            <span class="material-icons">list</span>
                          </button>
                        </div>
                        <div class='col'>
                          <button mat-icon-button class="text-black-50" type="button"
                                  title='Edit Quote Name'
                                  (click)='editQuoteName(row);loginService.doGoogleTracking("dashboard", "editQuoteName", "");'
                                  aria-label="edit">
                            <span class="material-icons">rate_review</span>
                          </button>
                        </div>
                        <div class='col'>
                          <button mat-icon-button class="text-black-50" (click)='row.ShowCloneAlert = !row.ShowCloneAlert'
                                  title='Clone This Quote' aria-label="content copy" type="button">
                            <span class="material-icons">content_copy</span>
                          </button>
                        </div>
                        <div class='col'>
                          <button mat-icon-button class="text-black-50"
                                  (click)='row.ShowDeleteAlert = !row.ShowDeleteAlert'
                                  title='Delete Quote' aria-label="delete" type="button">
                            <span class="material-icons">delete_forever</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- quote actions end -->
  
                    <!-- delete quote alert start -->
                    <div class='col-12 alert alert-warning text-center my-2 p-2' *ngIf='row.ShowDeleteAlert === true'>
                      <p [innerHTML]="sharedFunction.getUiMessageByCode('Dashboard-WARNING-DeleteQuote')">
                      </p>
                      <button mat-raised-button color="warn" class='mr-2 min-w-auto' type="button"
                              (click)='deleteQuote(row);loginService.doGoogleTracking("dashboard", "Delete Quote", "");'
                              title='Delete This Quote'>
                        YES
                      </button>
  
                      <button mat-raised-button color="qm-black" class='min-w-auto' type="button"
                              (click)='row.ShowDeleteAlert = false' title='Cancel'>
                        NO
                      </button>
                    </div>
                    <!-- delete quote alert end -->
  
                    <!-- clone quote start -->
                    <div class='col-12 alert alert-info text-center my-2 p-2' *ngIf='row.ShowCloneAlert === true'>
                      <p [innerHTML]="sharedFunction.getUiMessageByCode('Dashboard-INFO-CloneQuote')">
                      </p>
                      <button mat-raised-button color="warn" class='mr-2 min-w-auto' type="button"
                              (click)='cloneQuote(row);loginService.doGoogleTracking("dashboard", "clone Quote", "");'
                              title='Delete This Quote'>
                        YES
                      </button>
  
                      <button mat-raised-button color="qm-black" class='min-w-auto' type="button"
                              (click)='row.ShowCloneAlert = false' title='Cancel'>
                        NO
                      </button>
                    </div>
                    <!-- clone quote end -->
                  </mat-cell>
                </ng-container>
  
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
  
            </li>
            <!-- table end -->
  
            <!-- no quote data message -->
            <li class="list-group-item px-4 border-top-0" *ngIf="userSavedQuotes.length === 0">
              <div class='text-center'>
                <a (click)='loginService.doGoogleTracking("dashboard", "go to product and provider settings", "");'
                   [routerLink]="['/setting/quote']" mat-button color="qm-blue" class="qm-text-mid text-wrap w-sm-100">
                  {{sharedFunction.getUiMessageByCode("Dashboard-INFO-SelectProvider")}}
                </a>
              </div>
            </li>
          </ul>
        </div>
        <!-- paginator and archived button -->
        <div>
          <mat-paginator [pageSizeOptions]="[10, 25, 100]" [showFirstLastButtons]=true></mat-paginator>
  
          <div *ngIf='loginService.checkUserAccessRole(accessRole.USER_ROLE_QM) && userSavedQuotes.length !== 0' class='mt-2'>
            <a (click)='loginService.doGoogleTracking("dashboard", "showArchiveQuotes", "");'
               [routerLink]="['/dashboard-archives']" mat-flat-button color="qm-blue"
               class="qm-flat-btn-lg float-right w-sm-100">
              View Archived Quotes
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- quote table section end -->
  </div>
</div>
