<div class="mb-5">
  <kiwimonster-step-menu [currentKMQuoteStep]=2 class='quote-step-menu'></kiwimonster-step-menu>
</div>

<!-- quote name -->
<div class="form-group row">
  <label for="quoteName" class="col-auto col-form-label">Quote Name:</label>
  <div class='col-auto'>
    <input type="text" class='form-control' id='quoteName' disabled
           [(ngModel)]="currentKMQuote.QuoteName" />
  </div>
</div>

<ul class="list-group list-group-flush">
  <!-- header -->
  <li class="py-4 list-group-item qm-bg-catskillWhite">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
      <h4 class="mb-4 mb-md-0">KiwiSaver Details</h4>
      <div>
        <!-- go back button -->
        <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto'
                (click)='goBack();'>
          <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
          <span class="d-none d-sm-inline-block">Back</span>
        </button>

        <!-- save&close button -->
        <kiwimonster-save-close-btn (saveAndCloseClicked)="onSaveAndClose()"> </kiwimonster-save-close-btn>

        <!-- next button -->
        <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                (click)='goNext();'>
          <span class="d-none d-sm-inline-block">Next</span>
          <span class="material-icons" aria-hidden="false"
                aria-label="chevron right icon">chevron_right</span>
        </button>
      </div>
    </div>
  </li>

  <!-- client part -->
  <li class="list-group-item px-0 border-top-0">
    <div class="row">
      <div class='col-12'>
        <mat-tab-group animationDuration="0ms" class='main-client-group' [selectedIndex]='currentClientIndex'
                       appRealignInkBar>
          <!-- adult tab -->
          <mat-tab *ngFor="let client of currentKMQuote.Clients">
            <ng-container *ngIf="!client.IsDeleted && client.Setting.AreYouNZTaxResident">
              <!-- adult tab label -->
              <ng-template mat-tab-label>
                <ng-container *ngIf='client.Gender === 1'>
                  <img class='sm-img' src="/assets/images/client-type/1.png?v=2" />
                </ng-container>
                <ng-container *ngIf='client.Gender !== 1'>
                  <img class='sm-img' src="/assets/images/client-type/2.png?v=2" />
                </ng-container>
                {{getClientTabName(client)}}
              </ng-template>
              <!-- adult tab label end -->
        
              <!-- form -->
              <form #kiwiSaverSettingForm='ngForm'>
                <div class='row'>
                  <!-- circumstances -->
                  <div class="col-lg-4">
                    <div class="row">
                      <!-- section title -->
                      <div class="col-12 px-5 py-3">
                        <h3 class='text-blue'>Your Current Circumstances</h3>
                      </div>
        
                      <!-- DesiredRetirementAge -->
                      <div class='col-12 px-5 py-3'>
                        <app-select-search placeholderLabel='Anticipated Age for Accessing KiwiSaver Funds'
                                           [selectedData]='client.Setting.DesiredRetirementAge'
                                           [isRequired]='false'
                                           (onSelectedDataChange)="client.Setting.DesiredRetirementAge=$event; onValueChange()"
                                           [selectOptions]='desiredRetirementAgeList'
                                           *ngIf="desiredRetirementAgeList.length > 0">
                        </app-select-search>
                      </div>

                      <!-- Do you have an existing KiwiSaver fund? -->
                      <div class='col-12 px-5 py-3'>
                        <mat-form-field>
                          <mat-select placeholder="Do you have an existing KiwiSaver fund?" name='doYouHaveKiwiSaver'
                                      [(ngModel)]="client.Setting.DoYouHaveKiwiSaver"
                                      (selectionChange)='onValueChange(); setCurrentSchemeFund(client.Setting.DoYouHaveKiwiSaver, client)'>
                            <mat-option [value]="true">Yes</mat-option>
                            <mat-option [value]="false">No</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
        
                      <!-- Yearly gross earnings -->
                      <div class='col-12 px-5 py-3'>
                        <mat-form-field>
                          <input matInput class='high-input'
                                 placeholder="Yearly gross earnings"
                                 name='yearlyGrossEarnings' currencyMask
                                 [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                 [(ngModel)]="client.Setting.YearlyGrossEarnings"
                                 (ngModelChange)='onValueChange(); calculatePIR(client)'
                                appAutoSelect>
                          <span matSuffix>&nbsp;&nbsp;/Year</span>
                          <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                        </mat-form-field>
                      </div>
        
                      <!-- KiwiSaverBalance -->
                      <div class='col-12 px-5 py-3'>
                        <mat-form-field>
                          <input matInput placeholder="KiwiSaver Balance"
                                 name='kiwiSaverBalance'
                                 currencyMask
                                 [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                 [(ngModel)]="client.Setting.KiwiSaverBalance"
                                 (ngModelChange)='onValueChange()'
                                appAutoSelect>
                          <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                        </mat-form-field>
                      </div>
        
                      <!-- ContributeInPercentage -->
                      <div class='col-12 px-5 py-3'>
                        <mat-form-field>
                          <input matInput type='number' class='text-right no-spin'
                                 placeholder="I Contribute (%)"
                                 name='contributeInPercentage'
                                 #contributeInPercentageInput='ngModel'
                                 pattern="^(100(\.0{1,})?|([0-9]{1,2}(\.\d+)?))$"
                                 [(ngModel)]="client.Setting.ContributeInPercentage"
                                 (ngModelChange)='onValueChange()'
                                 required appAutoSelect>
                          <span matSuffix>&nbsp;&nbsp;%</span>
                          <mat-error
                                     *ngIf="contributeInPercentageInput.errors && (contributeInPercentageInput.dirty || contributeInPercentageInput.touched) && contributeInPercentageInput.errors.pattern">
                            {{sharedFunction.getUiMessageByCode('Share-ERROR-NumberBetween0and100')}}
                          </mat-error>
                          <mat-error
                                     *ngIf="contributeInPercentageInput.errors && (contributeInPercentageInput.dirty || contributeInPercentageInput.touched) && contributeInPercentageInput.errors.required">
                            {{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}
                          </mat-error>
                        </mat-form-field>
                      </div>
        
                      <!-- EmployerContributesPer -->
                      <div class='col-12 px-5 py-3'>
                        <mat-form-field>
                          <input matInput type='number' class='text-right no-spin'
                                 placeholder="My Employer Contributes (%)"
                                 name='employerContributesPer'
                                 #employerContributesPeInput='ngModel'
                                 pattern="^(100(\.0{1,})?|([0-9]{1,2}(\.\d+)?))$"
                                 [(ngModel)]="client.Setting.EmployerContributesPer"
                                 (ngModelChange)='onValueChange()'
                                 required appAutoSelect>
                          <span matSuffix>&nbsp;&nbsp;%</span>
                          <mat-error
                                     *ngIf="employerContributesPeInput.errors && (employerContributesPeInput.dirty || employerContributesPeInput.touched) && employerContributesPeInput.errors.pattern">{{sharedFunction.getUiMessageByCode('Share-ERROR-NumberBetween0and100')}}
                          </mat-error>
                          <mat-error
                                     *ngIf="employerContributesPeInput.errors && (employerContributesPeInput.dirty || employerContributesPeInput.touched) && employerContributesPeInput.errors.required">
                            {{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}
                          </mat-error>
                        </mat-form-field>
                      </div>
        
                      <!-- weeklyTopUp -->
                      <div class='col-12 px-5 py-3'>
                        <mat-form-field>
                          <input matInput placeholder="Weekly top-ups"
                                 name='weeklyTopUp'
                                 currencyMask
                                 [options]="{ prefix: '$ ', thousands: ',', precision : 0 , align : 'right'}"
                                 [(ngModel)]="client.Setting.WeeklyTopUp"
                                 (ngModelChange)='onValueChange()'
                                appAutoSelect>
                          <mat-error>{{sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')}}</mat-error>
                        </mat-form-field>
                      </div>

                      <!-- inflation -->
                      <div class='col-12 px-5'>
                        <mat-form-field>
                          <mat-select placeholder="Incorporate 2% inflation?" name='inflation'
                                      [(ngModel)]="client.Setting.Inflation"
                                      (selectionChange)='onValueChange();'>
                            <mat-option [value]="true">Yes</mat-option>
                            <mat-option [value]="false">No</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
        
                  <!-- current fund -->
                  <div class="col-lg-4 border-left">
                    <div class="row">
                      <!-- section title -->
                      <div class="col-12 px-5 py-3">
                        <h3 class='text-blue'>Your Current KiwiSaver Fund</h3>
                      </div>

                      <!-- Current Scheme -->
                      <div class='col-12 px-5 py-3'>
                        <app-select-search placeholderLabel='Current Scheme' [isRequired]='true'
                                           [isDisabled]='!client.Setting.DoYouHaveKiwiSaver'
                                           selectElementName='currentScheme'
                                           [selectedData]='client.Setting.QmKSSchemeNumber'
                                           (onSelectedDataChange)="client.Setting.QmKSSchemeNumber=$event; onValueChange(); onCurrentSchemeValueChange(client)"
                                           [selectOptions]='schemeList'
                                           optionValueProperty='SchemeNumber'
                                           displayValue='SchemeName'
                                           [errorMessage]="sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired')"
                                           *ngIf="schemeList?.length > 0">
                        </app-select-search>
                      </div>
        
                      <!-- Current Fund -->
                      <div class='col-12 px-5 py-3'>
                        <app-select-search placeholderLabel='Current Fund' [isRequired]='true'
                                           [isDisabled]='!client.Setting.DoYouHaveKiwiSaver'
                                           selectElementName='currentFund'
                                           [selectedData]='client.Setting.CurrentSchemeFund'
                                           (onSelectedDataChange)="client.Setting.CurrentSchemeFund=$event; onValueChange(); onCurrentFundValueChange(client)"
                                           [selectOptions]='client.Setting.CurrentSchemeFunds'
                                           [detectSelectOptionsChange]='true'
                                           compareSameProperty='Id'
                                           [errorMessage]="client.Setting.CurrentSchemeFunds.length > 0 ?sharedFunction.getUiMessageByCode('Share-ERROR-FieldRequired'):'Please select a scheme first.'"
                                           [hintMessage]='client.Setting.CurrentSchemeFunds.length===0?"Please select a scheme first.":""'
                                           displayValue='FundName'>
                        </app-select-search>
                      </div>
                    </div>
                  </div>
        
                  <!-- prospective fund -->
                  <div class="col-lg-4 border-left">
                    <div class="row">
        
                      <!-- section title -->
                      <div class="col-12 px-5 py-3">
                        <h3 class='text-blue'>Your Prospective KiwiSaver Funds</h3>
                      </div>
        
                      <!-- ProspectiveFundDuration-->
                      <div class="col-12 px-5 py-3">
                        <mat-form-field>
                          <mat-select placeholder="I want to use my KiwiSaver money in" name='prospectiveFundDuration'
                                      [(ngModel)]="client.Setting.ProspectiveFundDuration"
                                      (selectionChange)='onValueChange()'>
                            <mat-option *ngFor="let duration of sysConfig.requiredData.KiwiMonsterFundDurationList"
                                        [value]="duration.Value">
                              {{ duration.Name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
        
                      <!-- ProspectiveFundRisk-->
                      <div class="col-12 px-5 py-3">
                        <mat-form-field>
                          <mat-select placeholder="Over a given year, I'm comfortable with this possible gain or loss'"
                                      name='prospectiveFundRisk'
                                      [(ngModel)]="client.Setting.ProspectiveFundRisk"
                                      (selectionChange)='onValueChange()'>
                            <mat-option *ngFor="let risk of sysConfig.requiredData.KiwiMonsterFundRiskList"
                                        [value]="risk.Value">
                              {{ risk.Name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
        
                      <!-- ProspectiveFundGoal-->
                      <div class="col-12 px-5 py-3 clickable"
                           (click)='client.Setting.HideGoalOptions = !client.Setting.HideGoalOptions'>
                        <p class='qm-text-xsm'>The most important thing to me when investing in KiwiSaver is...
                        </p>
                        <div class='d-flex border-secondary border-bottom justify-content-between'>
                          <p>
                            {{sharedFunction.getDisplayName("KiwiMonsterFundGoalList",client.Setting.ProspectiveFundGoal)}}
                          </p>
                          <p class='align-self-end text-black-50 mb-0'>
                            <span class="material-icons">arrow_drop_down</span>
                          </p>
                        </div>
                        <mat-card *ngIf="!client.Setting.HideGoalOptions">
                          <mat-radio-group name="{{'goal_' + client.Id}}" 
                                           [(ngModel)]="client.Setting.ProspectiveFundGoal">
                            <mat-radio-button (click)="client.Setting.ProspectiveFundGoal = goal.Value; onValueChange()"
                                              [checked]="client.Setting.ProspectiveFundGoal === goal.Value"
                                              *ngFor="let goal of sysConfig.requiredData.KiwiMonsterFundGoalList"
                                              [value]="goal.Value">
                              {{goal.Name}}
                            </mat-radio-button>
                          </mat-radio-group>
                        </mat-card>
                      </div>

                      <!-- Exclude Funds -->
                      <div class='col-12 px-5 py-3'>
                        <button class="w-100 text-wrap text-left qm-text-mid py-2 text-transform-none" type="button" mat-flat-button color='qm-gray'
                                (click)="openExcludeFundsDialog(client);loginService.doGoogleTracking('kiwiMonsterQuote-kiwiSaverDetail', 'openExcludeFundsDialog', '');">
                          {{sharedFunction.getUiMessageByCode('KiwiMonsterKiwiSaver-INFO-ExcludeFundsQuestion')}}
                        </button>
                      </div>
        
                      <!-- select fund button -->
                      <div class='col-12 px-5 py-3'>
                        <button class="w-100 text-wrap text-transform-none py-2" type="button" mat-flat-button color="qm-pumpkin"
                                (click)="doSelectProspectiveFunds(client);loginService.doGoogleTracking('kiwiMonsterQuote-kiwiSaverDetail', 'doSelectProspectiveFunds', '');">
                          <p class='my-1 qm-text-mid line-height-md'>Select Funds</p>
                      
                        </button>
                        <p class='line-height-md'>Based on your answers you are probably a
                          <strong class='text-pumpkin'>{{getSearchFundTypeName(client)}}</strong> type of investor.
                        </p>
                      </div>
                      <!-- no result alert -->
                      <div class="col-12 px-5">
                        <div class="alert alert-danger text-center" role="alert"
                             *ngIf="!client.Setting.HasFundSearchResults">
                          {{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}
                        </div>
                      </div>
                      <!-- selected fund name -->
                      <div class="col-12 px-5">
                        <div class="alert alert-info py-4" role="alert"
                             *ngIf="client.Setting.QmKSProspectiveProductFunds.length > 0">
                          <h6>Selected Prospective KiwiSaver Funds: </h6>
                          <ng-container *ngFor="let pFund of client.Setting.QmKSProspectiveProductFunds">
                            <li class='mb-0 ml-3 font-weight-bolder'>
                              {{pFund.SchemeName}} - {{pFund.FundName}}
                            </li>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </li>

  <!-- error messages -->
  <li class="list-group-item px-0" *ngIf='errorMessages.length > 0'>
    <div class="alert alert-danger" role="alert" *ngFor='let e of errorMessages'>
      {{e}}
    </div>
  </li>

  <!-- nav footer start -->
  <li class="list-group-item px-0">
    <button mat-raised-button color="qm-blue" class='float-left'
            (click)='goBack();'>
      <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
      Back
    </button>

    <button mat-raised-button color="qm-blue" class='float-right'
            (click)='goNext();'>
      Next
      <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
    </button>
  </li>
  <!-- nav footer end -->
</ul>