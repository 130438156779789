import { Injectable, isDevMode } from '@angular/core';
import { HttpRequestUrl } from '../models/http.request.models/request.url';
import { FactFindAccessPublic } from '../models/fact-find/fact-find-access-public.model';



@Injectable({
  providedIn: 'root',
})
export class ApiAddressService {

  private baseUrlTest: string = 'https://sandboxapiv3.quotemonster.co.nz/api/';
  //private baseUrlTest: string = 'http://localhost:4889/api/';
  private baseUrlLive: string = 'https://apiv2.quotemonster.co.nz/api/';
  private version: string = '';


  getBaseUrl(): string {
    if (isDevMode()) {

      return this.baseUrlTest;
    } else {
      return this.baseUrlLive;
    }
  }


  getOldSiteUrl(): string {
    if (isDevMode()) {
      return 'https://qa.quotemonster.co.nz/old/';
    } else {
      return 'https://secure.quotemonster.co.nz/ssl/default.aspx';
    }
  }


  getBlogUrl(): string {
    return this.getBaseUrl() + this.version + 'PublicData/GetBlogs';
  }




  getAccessTokenUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/GetAccess', 'POST'
    );
  }

  getRefreshAccessTokenUrl() {
    return this.getBaseUrl() + this.version + 'User/Authorize/RefreshAccess';
  }

  getTrackingApiErrorUrl() {
    return this.getBaseUrl() + this.version + 'Tracking/ApiError';
  }

  // get standard user MFA methods
  getAllMfaMethodsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFAs', 'GET'
    );
  }

  // get PA user MFA methods
  getPaAllMfaMethodsUrl(paUserId: number, deviceCode: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/MFAs?paUserId=' + paUserId + '&deviceCode=' + deviceCode, 'GET'
    );
  }

  // add standard user MFA method
  addMfaMethodUrl(methodCode: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA?methodCode=' + methodCode, 'POST'
    );
  }

  // add PA user MFA method
  addPaMfaMethodUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/MFA', 'POST'
    );
  }

  resendMfaVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA/ResendVCode', 'POST'
    );
  }

  resendPaMfaVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/MFA/ResendVCode', 'POST'
    );
  }

  getCreateUserUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/CreateAccount', 'POST'
    );
  }

  getUserSavedQuoteUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedQuotes', 'GET'
    );
  }

  getUserArchivedQuotesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/ArchivedQuotes', 'GET'
    );
  }

  getUserNoticeCounterUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/Count', 'GET'
    );
  }

  // Notice start

  getSystemNoticesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/SystemNotices', 'GET'
    );
  }

  getUserNoticeListUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/List', 'GET'
    );
  }

  getUserNoticeUrl(noticeId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/Notice?noticeId=' + noticeId, 'GET'
    );
  }

  deleteUserNoticeUrl(noticeId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/Notice?noticeId=' + noticeId, 'DELETE'
    );
  }


  getUserNoticeStartupNoticeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/StartUp', 'GET'
    );
  }

  markAllNoticeReadUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Notices/MarkAllRead', 'PUT'
    );
  }


  getInfoPageUrl(pageCode: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/InfoPage?key=' + pageCode, 'GET'
    );
  }

  getQprAndQeVersionUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData', 'GET'
    );
  }

  getQMTrackingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Tracking', 'PUT'
    );
  }

  getRequestReSetPasswordLinkUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/ReSetPassword', 'POST'
    );
  }

  getReSetPasswordByKeyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/UpdatePasswordByKey', 'PUT'
    );
  }


  // pa function
  getPaAccessTokenUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/GetAccess', 'POST'
    );
  }

  getPaRefreshAccessTokenUrl() {
    return this.getBaseUrl() + this.version + 'PaUser/Authorize/RefreshAccess';
  }

  getPaRefreshAccessTokenHttpRequestUrl(): HttpRequestUrl {
    return new HttpRequestUrl(this.getBaseUrl() + this.version + 'PaUser/Authorize/RefreshAccess', 'POST');
  }


  getUpdatePaPasswordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/UpdatePasswordByKey', 'PUT'
    );
  }

  getResetPaPasswordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/ReSetPassword', 'POST'
    );
  }

  getReSetPaPasswordByKeyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/UpdatePasswordByKey', 'POST'
    );
  }

  getUserSavedQuoteDetailUrl(quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedQuoteDetail?quoteId=' + quoteId, 'GET'
    );
  }


  deleteUserSavedQuoteUrl(quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/SavedQuote?quoteId=' + quoteId, 'DELETE'
    );
  }


  getUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'GET'
    );
  }


  resetUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'DELETE'
    );
  }


  updateUserProviderSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/ProviderSetting', 'PUT'
    );
  }

  removeUserCrossProviderUrl(providerId: number, benefitId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/CrossProviderSetting?providerId=' + providerId + '&benefitId=' + benefitId, 'DELETE'
    );
  }


  getUserQmSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/QmSiteSetting', 'GET'
    );
  }

  updateUserQmSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/QmSiteSetting', 'PUT'
    );
  }
    
  updateQuoteNameUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/QuoteName', 'POST'
    );
  }

  updateUserProviderSpecialSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/SpecialProviderSetting', 'PUT'
    );
  }

  getDefaultProviderOccupationSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/DefaultOccupationSetting', 'GET'
    );
  }


  getUserProviderOccupationSettingUrl(occId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/OccupationSetting?qmOccId=' + occId, 'GET'
    );
  }

  resetUserProviderOccupationSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/OccupationSetting', 'DELETE'
    );
  }


  updateUserProviderOccupationSetting(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/OccupationSetting', 'PUT'
    );
  }

  getUserDetailUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Detail', 'GET'
    );
  }

  updateUserDetailUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Detail', 'PUT'
    );
  }

  updateUserLogoUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Logo', 'FILE_UPLOAD'
    );
  }

  removeUserLogoUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Logo', 'DELETE'
    );
  }


  updateUserAvatarUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Avatar', 'FILE_UPLOAD'
    );
  }

  removeUserAvatarUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Avatar', 'DELETE'
    );
  }

  uploadSoaReportCoverPageImageUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection/CoverImage', 'FILE_UPLOAD'
    );
  }


  removeSoaReportCoverPageImageUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection/CoverImage', 'DELETE'
    );
  }

  getNeedAnalysisSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/NeedSetting', 'GET'
    );
  }

  updateNeedAnalysisSettingUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/NeedSetting', 'PUT'
    );
  }

  // soa report themes
  getSoaThemesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/SoaSetting', 'GET'
    );
  }

  updateUserSoaThemeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/SoaSetting', 'PUT'
    );
  }


  updateUserNameUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/UserName', 'PUT'
    );
  }

  updateUserPasswordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/UpdatePassword', 'PUT'
    );
  }

  updatePaUserPasswordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/UpdatePassword', 'POST'
    );
  }
    
  getAllowQmSuperPAStatusUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/AllowQmSuperPA', 'GET'
    );
  }
    
  updateAllowQmSuperPAStatusUrl(isAllowQmSuperPA:boolean): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/AllowQmSuperPA?isAllow=' + isAllowQmSuperPA, 'POST'
    );
  }
    
  // resend device verification code for standard user login
  getReSendUserDeviceVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/DeviceVerificationCode/ReSend', 'POST'
    );
  }

  // resend device verification code for PA user login
  getReSendPaUserDeviceVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/DeviceVerificationCode/ReSend', 'POST'
    );
  }

  // check standard user device verification code
  checkUserDeviceVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/DeviceVerificationCode/Check', 'POST'
    );
  }

  // check PA user device verification code
  checkPaUserDeviceVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/DeviceVerificationCode/Check', 'POST'
    );
  }

  // check standard user MFA verification code
  checkMFAVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA/Check', 'POST'
    );
  }

  // check PA user MFA verification code
  checkPaMFAVerificationCodeUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/MFA/Check', 'POST'
    );
  }

  getUserLoginDevicesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA/LoginDevices', 'GET'
    );
  }

  getPaUserLoginDevicesUrl(paUserId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/MFA/LoginDevices?paUserId=' + paUserId, 'GET'
    );
  }

  deleteLoginDevicesUrl(deviceId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA/LoginDevice?deviceIndex=' + deviceId, 'DELETE'
    );
  }

  deletePaLoginDevicesUrl(deviceId: number, paUserId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/MFA/LoginDevice?deviceIndex=' + deviceId + '&paUserId=' + paUserId, 'DELETE'
    );
  }

  updateLoginDeviceNameUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Authorize/MFA/LoginDeviceName', 'POST'
    );
  }

  updatePaLoginDeviceNameUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PaUser/Authorize/MFA/LoginDeviceName', 'POST'
    );
  }

  getReportHistoryListUrl(quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/ReportHistory?quoteId=' + quoteId, 'GET'
    );
  }



  getNeedAnalysisUrl(ncId: number, quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/NeedAnalysis?ncId=' + ncId + '&quoteId=' + quoteId, 'GET'
    );
  }

  updateNeedAnalysisUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/NeedAnalysis', 'POST'
    );
  }


  getPolicyDocumentRequiredDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PolicyDoc/RequiredData', 'GET'
    );
  }


  getQprRequiredDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Research/RequiredData', 'GET'
    );
  }

  getPolicyDocumentListUrl(companyId, benefitId, productId, documentId, month, year): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version
      + 'PolicyDoc?companyId=' + companyId
      + '&benefitIdsString=' + benefitId
      + '&productId=' + productId
      + '&documentId=' + documentId
      + '&month=' + month
      + '&year=' + year, 'GET'
    );
  }

  // quote
  getDoCrunchUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote', 'POST'
    );
  }


  getQprPolicyWordingRequiredDataUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PolicyWording/RequiredData', 'GET'
    );
  }


  getQprItemDetailListUrl(productId: number, qprItemId: number, selectedProviderIds: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'PolicyWording?productId=' + productId
      + '&qprItemId=' + qprItemId
      + '&selectedProviderIds=' + selectedProviderIds, 'GET'
    );
  }

  getQuoteQprItemDetailListUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'PolicyWording/MixPolicyWording'
      , 'POST'
    );
  }

  getQmadsUrl(position: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/QmAds?position=' + position, 'GET'
    );
  }




  getCheckFspDetailUrl(fsp: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/CheckFsp?fsp=' + fsp, 'GET'
    );
  }

  getCheckEmailUrl(email: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/CheckEmail?email=' + email, 'GET'
    );
  }


  getQprResultV3Url(quoteId: number, pCodes: string, cIndex: number, bIndex: number, orderBy: number, seriousTrauma?: number) {
    let url = this.getBaseUrl()
      + this.version
      + 'Research/QprRating?quoteId=' + quoteId
      + '&pCodes=' + pCodes
      + '&cIndex=' + cIndex
      + '&bIndex=' + bIndex
      + '&orderBy=' + orderBy;

    if (seriousTrauma === 1) {
      url += '&seriousTrauma=' + seriousTrauma;
    }

    return new HttpRequestUrl(url, 'GET');
  }


  getHeadToHeadResultUrl(quoteId: number, pCodes: string, cIndex: number, bIndex: number, isSeriousTrauma: number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Research/HeadToHead?quoteId=' + quoteId
      + '&pCodes=' + pCodes
      + '&cIndex=' + cIndex
      + '&bIndex=' + bIndex
      + '&isSeriousTrauma=' + isSeriousTrauma, 'GET'
    );
  }


  getQuoteResultReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/QuoteResult',
      'POST'
    );
  }


  getBankPremiumUrl(quoteId: number, selectedProviderQprCodes: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Quote/QuoteForAll?appId=' + quoteId
      + '&cqCodes=' + selectedProviderQprCodes,
      'GET'
    );
  }

  get10YearPremiumEstimateUrl(quoteId: number, selectedProviderQprCodes: string, clientIndex: number, benefitIndex: number) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Quote/TenYearsPremium?appId=' + quoteId
      + '&cqCodes=' + selectedProviderQprCodes
      + '&cId=' + clientIndex
      + '&bId=' + benefitIndex,
      'GET'
    );

  }

  getHeadToHeadReportUrl() {

    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/HeadToHead',
      'POST'
    );

  }




  getDefaultCommissionSettingItem() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/DefaultCommissionSetting',
      'GET'
    );
  }

  getUserCommissionSettings() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/CommissionSetting',
      'GET'
    );
  }

  addOrUpdateUserCommissionSettings() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Setting/CommissionSetting',
      'POST'
    );
  }


  getUserProfileUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Profile',
      'GET'
    );
  }

  updateUserProfileUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'User/Profile',
      'PUT'
    );
  }



  // licenase service start
  getAllAvailableLicensesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Licenses/All', 'GET'
    );
  }

  getAllAvailableLicensesForPublicUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Licenses/Public', 'GET'
    );
  }


  getOwnLicensesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Licenses/Own', 'GET'
    );
  }

  cancelLicenseUrl(licenseId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order/Cancel?licenseId=' + licenseId, 'DELETE'
    );
  }

  cancelAllLicensesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order/CancelAll', 'DELETE'
    );
  }

  getLicenseUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/License', 'GET'
    );
  }

  getPaymentMethodUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/PaymentMethods', 'GET'
    );
  }

  getAllPaymentMethodsUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/AllPaymentMethods', 'GET'
    );
  }

  addPaymentMethodUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/PaymentMethod', 'POST'
    );
  }

  updatePaymentMethodUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/PaymentMethod', 'PUT'
    );
  }

  getBillingAddressesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/BillingAddresses', 'GET'
    );
  }

  addOrUpdateBillingAddressUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/BillingAddress', 'POST'
    );
  }

  getPrepareOrderUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order', 'POST'
    );
  }

  getOrderUrl(orderStamp: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order?orderStamp=' + orderStamp, 'GET'
    );
  }


  submitOrderUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order', 'PUT'
    );
  }


  getOrderStatusUrl(orderId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Order/Status?orderId=' + orderId, 'GET'
    );
  }


  getUserSubscriptionInvoicesUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Invoices', 'GET'
    );
  }




  // license service end


  getUnderwritingUrl(quoteId: number, selectedQprProviderIds: string,
    clientIndex: number, bmi: number, height: number = 0, weight: number = 0,
    lifeInForce: number = 0, traumaInForce: number = 0, tpdInForce: number = 0) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version
      + 'Research/MedicalRequirement?quoteId=' + quoteId
      + '&qpCodes=' + selectedQprProviderIds
      + '&ci=' + clientIndex
      + '&bmi=' + bmi
      + '&height=' + height
      + '&weight=' + weight
      + '&lifeInForce=' + lifeInForce
      + '&traumaInForce=' + traumaInForce
      + '&tpdInForce=' + tpdInForce

      , 'GET'
    );
  }



  cloneUserSavedQuoteUrl(quoteId: number) {

    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/CloneQuote?appId=' + quoteId, 'GET'
    );
  }


  getReportV2Url() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/FullReport', 'POST'
    );
  }

  getSoaReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/SOA', 'POST'
    );
  }

  getSoaReporAsynctUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/SOAAsync', 'POST'
    );
  }

  checkSoaReportStatusUrl(reportId: number){
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/CheckSOAReportStatus?reportId=' + reportId, 'POST'
    );
  }

  getAppReports(appId: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/AppReports?appId=' + appId, 'GET'
    );
  }


  getEmailReports() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/EmailReports', 'POST'
    );
  }





  getSOAReplacementItems(defSectionId: number | null) {
    let query: string = "";
    if (defSectionId !== null) {
      query = '?defSectionId=' + defSectionId;
    }
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOAReplacementItems' + query, 'GET'
    );
  }

  getUserSOASections() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASections', 'GET'
    );
  }

  resetUserSOASections() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASections', 'DELETE'
    );
  }


  updateUserSOASectionsOrder() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASections', 'PUT'
    );
  }


  getUserSOASection(userOwnSectionId, defSectionId) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection?userOwnSectionId=' + userOwnSectionId + '&defSectionId=' + defSectionId, 'GET'
    );
  }

  getUserSOAReportCustomisations() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOAReportCustomisations', 'GET'
    );
  }

  updateUserSOAReportCustomisation() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOAReportCustomisation', 'PUT'
    );
  }

  createOrUpdateUserSOASection() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/SOASection', 'PUT'
    );
  }

  getUserIsUsingGroupSoa() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/IsUsingGroupSoa', 'GET'
    );
  }
    
  createSOAPreviewReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/PreviewSOA', 'POST'
    );
  }

  updateUserIsUsingGroupSoa(useOwnTheme: boolean) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'NeedAnalysisService/IsUsingGroupSoa?usingGroupTheme=' + useOwnTheme, 'PUT'
    );
  }

  getPolicyWordingReportUrl(productId: number, qprItemId: number, selectedProviderIds: string) {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/PolicyWording?productId=' + productId
      + '&qprItemId=' + qprItemId
      + '&selectedProviderIds=' + selectedProviderIds, 'GET'
    );
  }


  getMixProductPolicyWordingReport() {
    return new HttpRequestUrl(
      this.getBaseUrl()
      + this.version
      + 'Report/MixPolicyWording', 'POST'
    );
  }


  getRequiredJsonUrl() {
    return '/assets/requiredData.json';
  }


  getVerifyGoogleReCaptchaUrl() {
    return this.getBaseUrl() + this.version + 'Tracking/VerifyGEC';
  }























  // no longer using
  getUserFeedbackUrl() {
    return this.getBaseUrl() + this.version + 'User/UserFeedback';
  }

  getAnonymousFeedbackUrl() {
    return this.getBaseUrl() + this.version + 'User/AnonymousFeedback';
  }


  getSystemAlertsUrl() {
    return this.getBaseUrl() + this.version + 'User/Notice/GetAvailableSystemAlerts';
  }


  getInsurerSelectionUrl() {
    return this.getBaseUrl() + this.version + 'QPR/InsurerSelectionList/Get';
  }








  // 2 factor login api
  getVerificationMethodsUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/GetVerificationMethods';
  }

  getCreateNewVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/CreateNewVerificationMethod';
  }

  getRemoveVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/RemoveVerificationMethod';
  }

  getCheckAndUpdateVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/CheckAndUpdateVerificationMethod';
  }

  getReSendVerificationMethodCodeUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/ReSendVerificationMethodCode';
  }

  getSetDefaultVerificationMethodUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/SetDefaultVerificationMethod';
  }

  getUserDevicesUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/GetUserDevices';
  }

  getRemoveUserDeviceUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/RemoveUserDevice';
  }


  getUpdateUserDeviceNameUrl() {
    return this.getBaseUrl() + this.version + 'User/Security/UpdateUserDeviceName';
  }

  // Adviser claim experiences

  getACEInfoItemListUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/AdviserClaimsExperiences', 'GET'
    );
  }

  getACEDetailItemUrl(aceId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/AdviserClaimsExperience?aceId=' + aceId, 'GET'
    );
  }

  deleteACEDetailItemUrl(aceId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/AdviserClaimsExperience?aceId=' + aceId, 'DELETE'
    );
  }

  getACEProvidersUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/Providers', 'GET'
    );
  }

  getACESelectOptionGroupsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/SelectOptionGroups', 'GET'
    );
  }

  postACEUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/AdviserClaimsExperience', 'POST'
    );
  }

  postACECommentUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Ace/Comment', 'POST'
    );
  }

  // CPD
  putCpdDataUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PublicData/CPD', 'PUT'
    );
  }

  // legacy standalone head to head
  getStandaloneH2HProductLinesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/ProductLines', 'GET'
    );
  }
    
  getLegacyQprSearchUrl(productType: string, productLineCode: string, qprCompanyId = '', legacyCompanyId = '', productCode = '', productCodeExt = '') {
    let publishType = 1;
    if(isDevMode()){
      publishType = 3; // show all 
    }
    return new HttpRequestUrl(
        this.getBaseUrl() + this.version + `LegacyQpr/Search?qprCompanyId=${ qprCompanyId }&legacyCompanyId=${ legacyCompanyId }&productType=${ productType }&productLineCode=${ productLineCode }&productCode=${ productCode }&productCodeExt=${ productCodeExt }&publishStatus=${publishType}`, 'GET'
    );
  }
    
  getLegacyH2HUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'LegacyQpr/Head2Head', 'POST'
    );    
  }
    
  getLegacyH2HReportUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Report/LegacyHeadToHead', 'POST'
    );    
  }
//   postStandaloneH2HUrl() {
//     return new HttpRequestUrl(
//       this.getBaseUrl() + this.version + 'Research/SHeadToHead', 'POST'
//     );
//   }

//   postStandaloneH2HReportUrl() {
//     return new HttpRequestUrl(
//       this.getBaseUrl() + this.version + 'Report/SHeadToHead', 'POST'
//     );
//   }
    
  userDeviceTrackingUrl() {
    return new HttpRequestUrl(this.getBaseUrl() + this.version + 'Tracking/UserDeviceTracking', 'POST');
  }
    
  uploadInsurancePolicyUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/CreateQuoteBaseOnFile', 'FILE_UPLOAD'
    );
  }

  checkUploadFileProcessStatusUrl(taskId: number): HttpRequestUrl{
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Quote/CheckQuoteBaseOnFileStatus?taskId=' + taskId, 'GET'
    );
  }
    
  // fact find
  getFactFindsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Fact/FactFinds', 'GET'
    );
  }
    
  createFactFindUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Fact/FactFind', 'POST'
    );
  }
    
  deleteFactFindUrl(factFindId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Fact/FactFind?factFindId=' + factFindId, 'DELETE'
    );
  }
    
  updateFactFindPasswordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Fact/RePasswordFactFind', 'PUT'
    );
  }
    
  getQmFactFindDetailUrl(id: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `Fact/FactFind?factFindId=${id}`, 'GET'
    );
  }
    
  saveQmFactFindDetailUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Fact/FactFind', 'PUT'
    );
  }
    
  getPublicFactFindUrl(factFindAccessPublic: FactFindAccessPublic): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `Fact/PublicFactFind?urlCode=${factFindAccessPublic.Key}&password=${factFindAccessPublic.AccessCode}`, 'GET'
    );
  }
    
  savePublicFactFindUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Fact/PublicFactFind', 'PUT'
    );
  }
    
  factFindToQuoteUrl(factFindId:number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `Fact/FactFindToQuote?factFindId=${factFindId}`, 'POST'
    );
  }

  factFindGetAdviserDetailByKey(key: string): HttpRequestUrl{
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `Fact/PublicFactFind/AdviserDetail?urlCode=${key}`, 'GET'
    );
  }
    
  // business insurance
  getSavedBusinessRecordsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusinessCover/Businesses', 'GET'
    );
  }
    
  getSavedBusinessDetailUrl(id: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `BusinessCover/Business?businessId=${id}`, 'GET'
    );
  }
    
  createUpdateBusinessRecordUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusinessCover/Business', 'POST'
    );
  }
    
  deleteSavedBusinessRecordUrl(businessRecordId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusinessCover/Business?businessId=' + businessRecordId, 'DELETE'
    );
  }
    
  resetBusinessProviderSettingsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/BusProviderSettings', 'DELETE'
    );
  }

  getBusinessProviderSettingsUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'User/Setting/BusProviderSettings', 'GET'
    );
  }
    
  turnOffBusinessProviderSettingUrl(providerId: number, turnOffProvider: boolean): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `User/Setting/BusProviderSetting?providerId=${providerId}&isTurnOffProvider=${turnOffProvider}`, 'PUT'
    );
  }
    
  changeBusinessProviderDefaultProductUrl(providerId: number, benefitId: number, productId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `User/Setting/BusProviderSetting?providerId=${providerId}&benefitId=${benefitId}&productId=${productId}`, 'PUT'
    );
  }
    
  getBusinessQuotesAndNcsUrl(businessId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QuoteForBusiness/QuotesAndNcs?businessId=${businessId}`, 'GET'
    );
  } 

  quoteForBusinessUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'QuoteForBusiness', 'POST'
    );
  }
    
  quoteForBusinessQuoteDetailUrl(businessId: number, quoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QuoteForBusiness/QuoteDetail?businessId=${businessId}&quoteId=${quoteId}`, 'GET'
    );
  }

  getBusinessQuoteResultReportUrl() {
      return new HttpRequestUrl(
        this.getBaseUrl() + this.version + 'BusinessReport/QuoteResult', 'POST'
      );
  }
    
  getBusinessHeadToHeadResultUrl(businessId: number, quoteId: number, pCodes: string, cIndex: number, bIndex: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `BusinessResearch/HeadToHead?businessId=${businessId}&quoteId=${quoteId}&pCodes=${pCodes}&cIndex=${cIndex}&bIndex=${bIndex}`, 'GET'
    );
  }
    
  getBusiness10YearPremiumEstimateUrl(businessId: number, quoteId: number, cpCodes: string, cIndex: number, bIndex: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `QuoteForBusiness/TenYearsPremium?businessId=${businessId}&appId=${quoteId}&cqCodes=${cpCodes}&cId=${cIndex}&bId=${bIndex}`, 'GET'
    );
  }
    
  getBusinessHeadToHeadReportUrl() {
      return new HttpRequestUrl(
        this.getBaseUrl() + this.version + 'BusinessReport/HeadToHead', 'POST'
      );
  }
    
    
  getBusinessQuoteQprItemDetailListUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusinessPolicyWording/MixPolicyWording', 'POST'
    );
  }
    
  getBusinessMixProductPolicyWordingReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusinessReport/MixPolicyWording', 'POST'
    );
  }
    
  getBusinessQprRequiredDataUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusinessResearch/RequiredData', 'GET'
    );
  }
    
  getBusinessResearchUrl(businessId: number, quoteId: number, pCodes: string, cIndex: number, bIndex: number, orderById: number) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `BusinessResearch/QprRating?businessId=${businessId}&quoteId=${quoteId}&pCodes=${pCodes}&cIndex=${cIndex}&bIndex=${bIndex}&orderBy=${orderById}`, 'GET'
    );
  }
    
  getBusinessFullReportUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
        this.getBaseUrl() + this.version + 'BusinessReport/FullReport', 'POST'
    );
  }
    
  getBusinessSoaReportUrl() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusinessReport/SOA', 'POST'
    );
  }
    
  getBusinessEmailReports() {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusinessReport/EmailReports', 'POST'
    );
  }
    
  getNZBNSearchResultsUrl(businessName: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `BusinessCover/NZBNSearch?name=${businessName}`, 'GET'
    );
  }
    
  getNZBNDetailsUrl(nzbn: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `BusinessCover/NZBNDetail?nzbn=${nzbn}`, 'GET'
    );
  }
  
  getNZBNAiSummaryUrl(website: string) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `BusinessCover/NZBNAiSummary?website=${website}`, 'GET'
    );
  }

  // business soa setting 
  getBusinessUserIsUsingGroupSoa(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusNeedAnalysisService/IsUsingGroupSoa', 'GET'
    );
  }
    
  getBusinessUserSOASections(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusNeedAnalysisService/SOASections', 'GET'
    );
  }
    
  getBusinessSOAReplacementItems(defSectionId: number | null): HttpRequestUrl {
    let query: string = "";
    if (defSectionId !== null) {
      query = '?defSectionId=' + defSectionId;
    }
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusNeedAnalysisService/SOAReplacementItems' + query, 'GET'
    );
  }

  resetBusinessUserSOASections(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusNeedAnalysisService/SOASections', 'DELETE'
    );
  }

  createOrUpdateBusinessUserSOASection(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusNeedAnalysisService/SOASection', 'PUT'
    );
  }

  updateBusinessUserSOASectionsOrder(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusNeedAnalysisService/SOASections', 'PUT'
    );
  }
    
  getBusinessUserSOASection(userOwnSectionId, defSectionId): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusNeedAnalysisService/SOASection?userOwnSectionId=' + userOwnSectionId + '&defSectionId=' + defSectionId, 'GET'
    );
  }
    
  removeBusinessSoaReportCoverPageImageUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusNeedAnalysisService/SOASection/CoverImage', 'DELETE'
    );
  }

  uploadBusinessSoaReportCoverPageImageUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusNeedAnalysisService/SOASection/CoverImage', 'FILE_UPLOAD'
    );
  }
    
  updateBusinessUserIsUsingGroupSoa(useOwnTheme: boolean) {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'BusNeedAnalysisService/IsUsingGroupSoa?usingGroupTheme=' + useOwnTheme, 'PUT'
    );
  }

  getCheckmonsterOverview(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Checkmonster/Overview', 'POST'
    );
  }

  getCheckmonsterDetail(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Checkmonster/Detail', 'POST'
    );
  }

  getHouseholdTypesList(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Checkmonster/HouseholderTypes', 'GET'
    );
  }

  getIncomeTypesList(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Checkmonster/IncomeTypes', 'GET'
    );
  }

  getCheckmonsterAdviserComment(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'Checkmonster/AdviserComment', 'POST'
    );
  }
    
  getThirdPartyCompanyUrl(requestKey: string, cid: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `ThirdCompany/OAuth?requestKey=${requestKey}&cid=${cid}`, 'GET'
    );
  }
    
  confirmThirdPartyCompanyUrl(requestKey: string, cid: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `ThirdCompany/OAuth?requestKey=${requestKey}&cid=${cid}`, 'PUT'
    );
  }

  getAllThirdPartyCompaniesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'ThirdCompany/LinkedCompanies', 'GET'
    );
  }
    
  deleteThirdPartyCompanyUrl(companyId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `ThirdCompany/LinkedCompany?companyId=${ companyId }`, 'DELETE'
    );
  }

  getOneClickLoginRequiredDataUrl(requestKey: string, ck: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `ThirdCompany/OneClickLogin?requestKey=${requestKey}&ck=${ck}`, 'GET'
    );
  }
    
  getOneClickLoginAccessTokenUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'TPU/Authorize/GetAccessToken', 'POST'
    );
  }
    
  getOneClickLoginRefreshAccessTokenUrl() {
    return this.getBaseUrl() + this.version + 'TPU/Authorize/RefreshAccessToken';
  }
    
  getOneClickLoginRefreshAccessTokenHttpRequestUrl(): HttpRequestUrl {
    return new HttpRequestUrl(this.getBaseUrl() + this.version + 'TPU/Authorize/RefreshAccessToken', 'POST');
  }
    
  getPriceHistoryUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'PriceHistory/PriceHistory', 'POST'
    );
  }
    
  // kiwiMonster
  getKiwiMonsterQuotesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Quotes', 'GET'
    );
  }
  
  createKiwiMonsterQuoteUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Quote', 'POST'
    );
  }
    
  getExistingKiwiMonsterQuoteUrl(qmKiwiMonsterQuoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Quote?qmKiwiMonsterQuoteId=' + qmKiwiMonsterQuoteId, 'GET'
    );
  }
  
  deleteKiwiMonsterQuoteUrl(qmKiwiMonsterQuoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Quote?qmKiwiMonsterQuoteId=' + qmKiwiMonsterQuoteId, 'DELETE'
    );
  }
    
  getKiwiMonsterSchemesUrl(): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/Schemes', 'GET'
    );
  }

  getKiwiMonsterFundsBySchemeUrl(schemeNumber: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/SchemeFunds?schemeNumber=${ schemeNumber }`, 'GET'
    );
  }
    
  getKiwiMonsterFundsByRiskUrl(riskLow: number, riskHigh: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/SchemeFunds?riskFrom=${riskLow}&riskTo=${riskHigh}`, 'GET'
    );
  }
    
  getKiwiMonsterFundsByFundIdUrl(qmKSProspectiveProductFundId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/SchemeFunds?fundId=${qmKSProspectiveProductFundId}`, 'GET'
    );
  }
    
  getKiwiMonsterFundsByFundTypeUrl(fundType: string): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + `KiwiMonster/SchemeFunds?fundType=${fundType}`, 'GET'
    );
  }
  
  getKiwiMonsterCompareFundUrl(qmKiwiMonsterQuoteId: number): HttpRequestUrl {
    return new HttpRequestUrl(
      this.getBaseUrl() + this.version + 'KiwiMonster/CompareFunds?quoteId=' + qmKiwiMonsterQuoteId, 'POST'
    );
  }
}