import { Injectable } from '@angular/core';
import { ComponentBaseClass } from '../base';
import { KiwiMonsterService } from './kiwimonster.service';
import { KiwiMonsterScheme } from 'src/app/models/kiwimonster/kiwimonster-scheme.model';
import { Observable } from 'rxjs';
import { KiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { KiwiMonsterClient } from 'src/app/models/kiwimonster/kiwimonster-client.model';
import { SysConfigService } from '../sys.config';
import { ClientKiwiSaverSetting } from 'src/app/models/kiwimonster/kiwimonster-kiwisaver-setting.model';

@Injectable({
  providedIn: 'root',
})

export class KiwiMonsterKiwiSaverService extends ComponentBaseClass {

  constructor (
    private kiwiMonsterService: KiwiMonsterService,
    public sysConfig: SysConfigService
  ) {
    super();
  }

  onInit(): void {
  }


  getSchemesObservable(): Observable<KiwiMonsterScheme[]> {
    return new Observable<KiwiMonsterScheme[]>(observer => {
      this.kiwiMonsterService.getKiwiMonsterSchemes((response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  getFundsBySchemeNumberObservable(schemeNumber: string): Observable<KiwiMonsterFund[]> {
    return new Observable<KiwiMonsterFund[]>(observer => {
      this.kiwiMonsterService.getKiwiMonsterFundsByScheme(schemeNumber, (response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  getFundsByRiskObservable(riskLow: number, rickHigh: number): Observable<KiwiMonsterFund[]> {
    return new Observable<KiwiMonsterFund[]>(observer => {
      this.kiwiMonsterService.getKiwiMonsterFundsByRisk(riskLow, rickHigh, (response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }
  
  getFundsByFundIdObservable(qmKSProspectiveProductFundId: number): Observable<KiwiMonsterFund[]> {
    return new Observable<KiwiMonsterFund[]>(observer => {
      this.kiwiMonsterService.getKiwiMonsterFundsByFundId(qmKSProspectiveProductFundId, (response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  getFundsByFundTypeObservable(fundType: string): Observable<KiwiMonsterFund[]> {
    return new Observable<KiwiMonsterFund[]>(observer => {
      this.kiwiMonsterService.getKiwiMonsterFundsByFundType(fundType, (response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }


  createInitSettingObject(clients: KiwiMonsterClient[]): void {
    clients.forEach(c => {
      if (!c.Setting) {
        // create setting object
        c.Setting = new ClientKiwiSaverSetting();
        // init FundStatusInRetirementCode
        c.Setting.FundStatusInRetirementCode = this.sysConfig.requiredData.KiwiMonsterFundStatusInRetirementList[0].Value.toString();
      } else {
        // add UI property for existing Setting
        c.Setting.HasFundSearchResults = true;
        c.Setting.CurrentSchemeFunds = [];
        c.Setting.HideGoalOptions = true;
        c.Setting.ProspectiveFundGoal = 1;
      }
      
      if (!c.Setting.QmKSProspectiveProductFunds) {
      // if QmKSProspectiveProductFunds is null/undefined, set to []
        c.Setting.QmKSProspectiveProductFunds = [];
      } else if (c.Setting.QmKSProspectiveProductFunds.length > 0) {
        // if has QmKSProspectiveProductFunds, set IsSelectedAsProspective to true
        c.Setting.QmKSProspectiveProductFunds.forEach(f => f.IsSelectedAsProspective = true);
      }
      // set prospective fund search option
      c.Setting.ProspectiveFundDuration = ClientKiwiSaverSetting.getDefaultProspectiveFundDuration(c.Age);
      c.Setting.ProspectiveFundRisk = ClientKiwiSaverSetting.getDefaultProspectiveFundRisk(c.Age);
      
      // set more questions list
      if (!c.Setting.MoreQuestions || c.Setting.MoreQuestions.length === 0) {
        c.Setting.MoreQuestions = this.sysConfig.requiredData.KiwiMonsterMoreQuestionList;
      }
      
    });
  }

  setInitCurrentFundObservable(clients: KiwiMonsterClient[]): Observable<KiwiMonsterClient[]> {
    return new Observable<KiwiMonsterClient[]>(observer => {
      clients.forEach(c => {
        // only existing client call getFundsBySchemeNumber api
        if (c.Setting.QmKSSchemeNumber) {
          this.getFundsBySchemeNumberObservable(c.Setting.QmKSSchemeNumber).subscribe((response) => {
            c.Setting.CurrentSchemeFunds = response;
            c.Setting.CurrentSchemeFund = c.Setting.CurrentSchemeFunds.find(fund => fund.Id === c.Setting.QmKSProductFundId);
          });
        } else {
            c.Setting.CurrentSchemeFund = new KiwiMonsterFund();
        }
      });
      observer.next(clients);
      observer.complete();
    });
  }

  
  updateClientSettingId(currentClientList: KiwiMonsterClient[], responseClientList: KiwiMonsterClient[]): void {
    responseClientList.forEach(responseClient => {
      let matchingClient = currentClientList.find(currentClient => currentClient.Id === responseClient.Id);
      if (matchingClient) {
        matchingClient.Setting.Id = responseClient.Setting.Id;
      }
    });
  }
    
  getPIRFromYearlyGrossEarnings(earnings: number): number {
    if (earnings > 48000) {
      return 28.0;
    }

    if (earnings > 14000) {
      return 14.5;
    }

    return 10.5;
  }
  
  removeCurrentFundFromData(currentFundId: number | null, data: KiwiMonsterFund[]): KiwiMonsterFund[] {
    if (currentFundId === null) {
      return data;
    }
    let filteredData = data.filter(f => f.Id !== currentFundId);
    return filteredData;
  }

  getProspectiveFundType(goalValue: number, durationValue: number, riskValue: number): string {
    let prospectiveFundSearchOptions = this.sysConfig.requiredData.KiwiMonsterFundSearchOptionList;
    return prospectiveFundSearchOptions.find(option => option.GoalValue === goalValue && option.DurationValue === durationValue && option.RiskValue === riskValue).TypeCode;
  }
  
  removeSelectedFund(client: KiwiMonsterClient): void {
    if (client.Setting.QmKSProspectiveProductFunds.length > 0 && client.Setting.QmKSProductFundId) {
      client.Setting.QmKSProspectiveProductFunds.forEach(pF => {
        if (pF.Id === client.Setting.QmKSProductFundId) {
          pF.IsSelectedAsProspective = false;
        }
      });

      client.Setting.QmKSProspectiveProductFunds = client.Setting.QmKSProspectiveProductFunds.filter(pF => pF.IsSelectedAsProspective === true);
    }
  }
}